/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component, Fragment } from 'react';
import dynamic from 'next/dynamic';
import SlideToggle from '../ReactToggleSlider/ReactSlideToggle.umd';
import Block from '../../adapters/helpers/Block';
import { scrollIntoView } from '../../adapters/helpers/Scroll';
import HierarchyUtils from '../../adapters/helpers/HierarchyUtils';
import { Label } from '../Label/Label';
import PropTypes, { any } from 'prop-types';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import throttle from 'lodash/throttle';
import { MiniNavigationConstants, ContentConstants } from '../../adapters/helpers/Constants';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';
import Modal from 'react-modal';
import DOMPurify from 'isomorphic-dompurify';
const Image = dynamic(() => import('../Image/Image'));
const Icon = dynamic(() => import('../Icon/Icon'));
const ObLink = dynamic(() => import('../ObLink/ObLink'));

class MiniNavigation extends Component {

    _desktopBreakpoint = 992;
    constructor(props) {
        super(props);
        this.block = new Block(props);
        this.placeholderTitle = this.block.props.document.fields.placeholderTitle;
        this.renderHierarchy = this.renderHierarchy.bind(this);

        this.state = {
            isOpened: false,
            isModalOpen: false,
            closeModal: false,
            modelText: '',
            hoverImage: any
        }
    }

    componentDidMount() {
        window.addEventListener(MiniNavigationConstants.resize, this.handleResizeThrottled);
    }

    componentWillUnmount() {
        window.removeEventListener(MiniNavigationConstants.resize, this.handleResizeThrottled);
    }

    closeModal = () => {
        document.documentElement.style.overflow ='auto';
        this.setState({ isModalOpen: false });
    };

    openModal = (hoverImage, modelText) => {
        if(modelText && modelText!==''){
            this.setState({ isModalOpen: true, modelText: modelText });
        }
        if(hoverImage){
            this.setState({ isModalOpen: true, hoverImage: hoverImage });
        }
    };

    handleResize() {
        let windowWidth = window.innerWidth;

        if (windowWidth >= this._desktopBreakpoint && this.state.isOpened) {
            this.setState({
                isOpened: false,
            });
        }
    }

    handleResizeThrottled = throttle(this.handleResize.bind(this), 100);

    renderHierarchy(onClickHandler) {
        const hierarchy = this.block.getFieldValue(MiniNavigationConstants.hierarchy);
        const className = this.block.getFieldValue(ContentConstants.classNames);
        const contentOptions = this.block.getFieldValue(ContentConstants.contentOptions);
        const entity = this.props.extraAttributes?.entity;
        let destinations = hierarchy?.fields?.destinations;
        const shownIcon = hierarchy?.fields?.type;
        const isFullBioDescription = hierarchy?.fields?.isFullBioDescription;
        if(!destinations && entity){
            const { relatedLinks } = entity;
            destinations = relatedLinks;
        }
        const { isXSmall, isSmall } = this.props;
        const isMobile = isXSmall || isSmall;
        const customModalStyles = {
            overlay: {
                backgroundColor: 'transparent',
                zIndex: 200
            },
            content: {
                border: 'none',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                fontSize: '1.4rem',
                lineHeight: '2.1rem',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: isMobile ? '90%' : '70%',
                maxHeight: isMobile ? '85%' : '80%',
                backgroundColor: 'white'
            }
        };
        const scrollToView = (sectionId) => {
            const section = document.getElementById(`${sectionId}`);
            section && section.scrollIntoView();
        };

        const renderTileMenus = () => {
            return <div className={`content-options ${className}`}>
                {contentOptions.map(contentOption => {
                    const option = contentOption?.fields;
                    return (
                        <ObLink style={{ cursor: 'pointer' }} onClick={() => scrollToView(option?.ctaUrl)} key={option?.name} href={`#${option?.ctaUrl}`} className={Label({ label: option?.class })}>
                            {option?.icon && <Image className="post-purchase-hero-section__icon" image={option?.icon} />}
                            {option?.description && <p className="post-purchase-hero-section__description">{option?.description}</p>}
                            {option?.subIcon && <Image className="post-purchase-hero-section__sub-icon" image={option?.subIcon} />}
                        </ObLink>)
                })}
            </div>;
        };

        return (
            <Fragment>
                {destinations &&
                    <ul className='ob-mini-nav__list'>
                        {destinations.map((destination, index) => {
                            return (
                                <React.Fragment>
                                <li
                                    className='ob-mini-nav__list-item'
                                    key={index}
                                    onClick={() => this.openModal(destination?.fields?.hoverImage, destination?.fields?.fullBioDescription, destinations?.fields?.image?.fields?.alternateText)}
                                    data-index={index}>
                                    {HierarchyUtils.renderDestination(destination, null, null, onClickHandler)}
                                    {shownIcon === MiniNavigationConstants.downArrow &&
                                        <div className="chrevonDown-IconAlign" onClick={e => this.onClickHandlerBtnIcon(e, destination)}>
                                            <Icon name={MiniNavigationConstants.chevronDown} />
                                        </div>
                                    }
                                    {isFullBioDescription &&
                                        <div className="bio-card-text full-bio">
                                            <p>{shownIcon}</p>
                                            <div className="nav-bar" />
                                            <div className="chrevonDown-IconAlign">
                                                <div className="arrow-up " />
                                            </div>
                                        </div>
                                    }
                                </li>
                                {destination?.fields?.fullBioDescription && <Modal
                                    isOpen={this.state.isModalOpen}
                                    onRequestClose={this.closeModal}
                                    style={customModalStyles}
                                >
                                    <div
                                        className={"bio-close-button"}
                                        onClick={this.closeModal}
                                    >
                                        X
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.modelText) }}>
                                    </div>
                                </Modal>}
                                {destination?.fields?.isPopupImageNeed && destination?.fields?.fullBioDescription && <Modal
                                    isOpen={this.state.isModalOpen}
                                    onRequestClose={this.closeModal}
                                    style={customModalStyles}
                                >
                                    <div
                                        className={"bio-close-button"}
                                        onClick={this.closeModal}
                                    >
                                        X
                                    </div>
                                    <Image image={this.state?.hoverImage} />
                                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.modelText) }}>
                                    </div>
                                </Modal>}
                            </React.Fragment>
                            )
                        }                
                        )}
                    </ul>
                }
                {contentOptions && renderTileMenus()}
            </Fragment>
        )
    }
    onClickHandlerBtnIcon(event,currentTarget) {
        event.preventDefault();
        if (currentTarget) {
          const  currentURL = currentTarget?.fields?.url;
          const type = currentTarget?.fields?.type;
            if(currentURL !== '_blank' && type!=='external') {
                const element = document.querySelector(currentURL);
                scrollIntoView(element, { behavior: MiniNavigationConstants.smooth });
            } else if(type === 'external') {        
                window.open(currentURL);
            }
        }
    }

    onClickHandler(event) {
        event.preventDefault();
        const currentTarget = event.currentTarget;
        if (currentTarget) {
            const anchorId = currentTarget.getAttribute(MiniNavigationConstants.href);
            if(currentTarget.getAttribute(MiniNavigationConstants.target) !== '_blank') {
                const element = document.querySelector(anchorId);
                scrollIntoView(element, { behavior: MiniNavigationConstants.smooth });
            } else if(currentTarget.getAttribute(MiniNavigationConstants.target) === '_blank') {        
                const target = currentTarget.getAttribute(MiniNavigationConstants.target);
                window.open(anchorId, target);
            }
        }
    }

    render() {
        const hierarchy = this.block.getFieldValue(MiniNavigationConstants.hierarchy);
        let isEmpty = !hierarchy?.fields?.destinations && !this.props.extraAttributes?.entity?.relatedLinks ? 'empty' : '';
        const iscollapsibleElement = this.props?.document?.fields?.collapsibleElement;
        const className = this.block.getFieldValue(MiniNavigationConstants.classNames);
        const greyBackground = this.block.getFieldValue(MiniNavigationConstants.backgroundGrey) ? 'grey-background' : '';

        return (
            <div className={`ob-mini-nav ${className ? className : ''} ${greyBackground ? greyBackground : ''} ${isEmpty}`}>
                {(this.props.isLarge && isEmpty !== 'empty'  && !iscollapsibleElement) ? (
                    <SlideToggle
                        collapsed
                        duration={500}
                        onExpanding={() => {
                            this.setState({ isOpened: true })
                        }}
                        onCollapsing={() => {
                            this.setState({ isOpened: false })
                        }}>
                        {({ onToggle, setCollapsibleElement }) => (
                            <Fragment>
                                <button className='ob-mini-nav__title event_menu_click'
                                        aria-expanded={this.state.isOpened}
                                        data-action-detail={AnalyticsConstants.dropdownMenu}
                                        onClick={onToggle}>
                                    <span>{this.placeholderTitle}</span>
                                    <span className={`${this.state.isOpened ? 'is-open ' : ''}ob-mini-nav__icon`}>
                                        <Icon name={MiniNavigationConstants.chevronDown} />
                                    </span>
                                </button>

                                <div ref={setCollapsibleElement}>
                                    {this.renderHierarchy(this.onClickHandler.bind(this))}
                                </div>
                            </Fragment>
                        )}
                    </SlideToggle>
                ) : (
                        <Fragment>
                            {this.renderHierarchy(this.onClickHandler.bind(this))}
                        </Fragment>
                    )}
            </div>
        )
    }
}

export default mediaQueryHOC(MiniNavigation);
// This export is for unit testing (do not remove) :
export const MiniNavigationTest = MiniNavigation;

MiniNavigation.propTypes = {
    extraAttributes: PropTypes.object,
    entity: PropTypes.object,
    isLarge: PropTypes.bool,
    isXSmall: PropTypes.bool,
    isSmall: PropTypes.bool,
    document: PropTypes.object,
};
